<template>
  <looker-report :url="url" />
</template>

<script>
export default {
  components: {
    LookerReport: () => import('@/modules/external_bi/components/LookerReport')
  },
  data: () => ({
    url: 'https://lookerstudio.google.com/embed/reporting/af6da37d-81b8-4633-bca9-79cba9dea132/page/p_5qg9qo87ed'
  })
}
</script>
